//TODO:  navbar, search bar, pet sitters list and map
import SearchBar from "../components/SearchBar";
import GoogleMap from "../components/GoogleMap";

const HomePage = () => {
  return (
    <>
      <SearchBar />

      <GoogleMap />
    </>
  );
};

export default HomePage;
