import { Button } from "@chakra-ui/react";

const Footer = () => {
  return (
    <div>
      <Button variant="navbarIconBtn">Footer</Button>
    </div>
  );
};

export default Footer;
